import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { getInitialTranslations } from "helpers/translations"
import { useCallback, useState } from "react"
import { FaPlus, FaTimesCircle } from "react-icons/fa"
import { IoLanguage } from "react-icons/io5"
import { TranslationForm } from "./TranslationForm"
import { useTranslation } from "react-i18next"
import { useStateContext } from "contexts/userContext"

function getEmptyoptions() {
  return {
    id: null,
    uniqKey: Date.now(),
    name: "New option",
    name_translations: getInitialTranslations(),
    price: "0",
  }
}

function getEmptyAddon(newItemName) {
  return {
    id: null,
    uniqKey: Date.now(),
    name: newItemName,
    name_translations: getInitialTranslations(),
    options: [getEmptyoptions()],
  }
}

export function DishAddonForm({ items, onChange, newItemName, addingBtnText, withMaxSelectedField }) {
  const [translationData, setTranslationData] = useState(null)
  const { t } = useTranslation()
  const { language } = useStateContext()

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target

      const newItems = [...items]

      const [name, index, index2] = e.target.name.split("_")
      if (name === "name") {
        newItems[index] = { ...newItems[index], name: value }
      } else if (name === "maxQuantity") {
        let maxQuantityValue = parseInt(value)
        if (maxQuantityValue) {
          maxQuantityValue = Math.min(Math.max(1, +maxQuantityValue), newItems[index].options.length)
        }

        newItems[index] = { ...newItems[index], max_quantity: maxQuantityValue }
      } else if (name === "optionName" || name === "optionPrice") {
        const options = [...newItems[index].options]
        options[index2] = { ...options[index2] }

        if (name === "optionName") {
          options[index2].name = value
        } else if (name === "optionPrice") {
          options[index2].price = value
        }

        newItems[index] = { ...newItems[index], options }
      }

      onChange(newItems)
    },
    [items]
  )

  const onAdd = useCallback(() => {
    onChange([...items, getEmptyAddon(newItemName)])
  }, [items, newItemName])

  const onVariantAdd = useCallback(
    (e) => {
      const optionItemIndex = +e.currentTarget.dataset.index

      const newItems = [...items]

      newItems[optionItemIndex] = {
        ...newItems[optionItemIndex],
        options: [...newItems[optionItemIndex].options, getEmptyoptions()],
      }

      onChange(newItems)
    },
    [items]
  )

  const onRemove = useCallback(
    (e) => {
      const index = +e.currentTarget.dataset.index

      const newItems = [...items]
      newItems.splice(index, 1)

      onChange(newItems)
    },
    [items]
  )

  const onVariantRemove = useCallback((e) => {
    const index = +e.currentTarget.dataset.index
    const index2 = +e.currentTarget.dataset.index2

    const newItems = [...items]

    newItems[index].options = [...newItems[index].options]
    newItems[index].options.splice(index2, 1)

    if (newItems[index].max_quantity && +newItems[index].max_quantity > newItems[index].options.length) {
      newItems[index].max_quantity = newItems[index].options.length
    }

    onChange(newItems)
  })

  const onTranslateProp = useCallback(
    (e) => {
      const [propName, index, index2] = e.currentTarget.dataset.id.split("_")
      if (!propName) return

      if (propName === "name") {
        setTranslationData({
          basePhrase: items[index].name,
          translations: items[index].name_translations,
          transformData: (newTranslationsState, curItems) => {
            const newData = [...curItems]

            newData[index] = {
              ...newData[index],
              name: newTranslationsState.basePhrase,
              name_translations: newTranslationsState.translations,
            }

            return newData
          },
        })
      } else if (propName === "optionName") {
        setTranslationData({
          basePhrase: items[index].options[index2].name,
          translations: items[index].options[index2].name_translations,
          transformData: (newTranslationsState, curItems) => {
            const newData = [...curItems]

            newData[index].options[index2] = {
              ...newData[index].options[index2],
              name: newTranslationsState.basePhrase,
              name_translations: newTranslationsState.translations,            }

            return newData
          },
        })
      }
    },
    [items]
  )

  return (
    <>
      <div className="flex-column g15">
        {items.map((item, index) => (
          <div key={item.id ?? item.uniqKey} className="border-light radius-default p10 pos-relative">
            <div className="flex-column g10">
              <div className="flex-row flex-y-center g10">
                <TextField
                  name={`name_${index}`}
                  // value={item.name || ""}

                  value={
                    item.name_translations?.[language]?.trim()
                      ? item.name_translations[language]
                      : item.name?.trim() || ""
                  }
                  onChange={handleChange}
                  fullWidth
                  size="small"
                />

                <IconButton data-id={`name_${index}`} onClick={onTranslateProp}>
                  <IoLanguage />
                </IconButton>
              </div>

              {item.options.map((option, index2) => (
                <div key={option.id ?? option.uniqKey} className="flex-row flex-y-center g10">
                  <TextField
                    name={`optionName_${index}_${index2}`}
                    value={
                      option.name_translations?.[language]?.trim()
                        ? option.name_translations[language]
                        : option.name?.trim() || ""
                    }
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />

                  <IconButton data-id={`optionName_${index}_${index2}`} onClick={onTranslateProp}>
                    <IoLanguage />
                  </IconButton>

                  <TextField
                    name={`optionPrice_${index}_${index2}`}
                    value={option.price || ""}
                    type="number"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />

                  {item.options.length !== 1 ? (
                    <IconButton size="small" data-index={index} data-index2={index2} onClick={onVariantRemove}>
                      <FaTimesCircle />
                    </IconButton>
                  ) : null}
                </div>
              ))}

              <div className="flex-row flex-x-space-between g10">
                <Button startIcon={<FaPlus size={12} />} option="outlined" data-index={index} onClick={onVariantAdd}>
                  {t("Add option")}
                </Button>

                {withMaxSelectedField && (
                  <div className="flex-row flex-y-center g10">
                    <span
                      className="gray"
                      style={{
                        maxWidth: "100px",
                        lineHeight: "1.2",
                        fontSize: "14px",
                        textAlign: "right",
                      }}
                    >
                      {t("Maximum of selected")}
                    </span>

                    <Select
                      name={`maxQuantity_${index}`}
                      value={item.max_quantity || ""}
                      onChange={handleChange}
                      sx={{ width: "65px" }}
                      size="small"
                    >
                      {["", ...Object.keys(item.options).map((i) => +i + 1)].map((key) => (
                        <MenuItem key={key} value={key}>
                          {key || <>&nbsp;</>}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            </div>

            <div className="pos-absolute white-bg radius-full" style={{ top: "-14px", right: "-14px" }}>
              <IconButton data-index={index} onClick={onRemove}>
                <FaTimesCircle />
              </IconButton>
            </div>
          </div>
        ))}

        <div>
          <Button startIcon={<FaPlus size={12} />} option="outlined" onClick={onAdd}>
            {addingBtnText}
          </Button>
        </div>
      </div>
      {console.log("translationData", translationData)}{" "}
      {translationData ? (
        //all in version
        // <TranslationForm
        //   basePhrase={translationData.basePhrase}
        //   translations={translationData.translations}
        //   onClose={() => setTranslationData(null)}
        //   onSave={(newTranslationsState) => {
        //     console.log("newTranslationsState", newTranslationsState)

        //     const newData = translationData.transformData(newTranslationsState, items)

        //     // // Если это основной `name`, обновляем его значением из перевода
        //     // if (translationData.basePhrase === items[0]?.name) {
        //     //   newData[0].name = newTranslationsState.basePhrase
        //     // }

        //     setTranslationData(null)
        //     onChange(newData)
        //   }}
        // />

        //original
        // <TranslationForm
        //   basePhrase={translationData.basePhrase}
        //   translations={translationData.translations}
        //   onClose={() => setTranslationData(null)}
        //   onSave={(newTranslationsState) => {
        //     console.log("newTranslationsState", newTranslationsState)
        //     const newData = translationData.transformData(newTranslationsState, items)
        //     console.log("newData", newData)
        //     setTranslationData(null)
        //     onChange(newData)
        //   }}
        // />

        //object version
        <TranslationForm
          basePhrase={translationData.basePhrase}
          translations={translationData.translations}
          onClose={() => setTranslationData(null)}
          onSave={(newTranslationsState) => {
            const newData = translationData.transformData(newTranslationsState, items)
            // const newData = translationData.transformData(newTranslationsState.translations, items)
            onChange(newData)
            setTranslationData(null)

          }}
        />
      ) : null}
    </>
  )
}
