import en from "translations/en.json"
import ru from "translations/ru.json"
import he from "translations/he.json"
import fr from "translations/fr.json"
import hu from "translations/hu.json"

import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import Backend from "i18next-xhr-backend"
import LanguageDetector from "i18next-browser-languagedetector"

console.log(navigator.languages); // массив языков, первый — системный
console.log(navigator.language); // например, "ru-RU" или "en-US"

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  he: {
    translation: he,
  },
  fr: {
    translation: fr,
  },
  hu: {
    translation: hu,
  },
}

const getLanguage = () => {
  const savedLang = localStorage.getItem("language");
  if (savedLang) return JSON.parse(savedLang);
  
  const browserLang = navigator.language || "en";
  return browserLang.split("-")[0]; // Оставляем только первые две буквы
};

console.log("getLanguage()", getLanguage())

// eslint-disable-next-line
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    lng: getLanguage(), // Получаем язык с учетом localStorage
    fallbackLng: "en",
  });

 
export default i18n
