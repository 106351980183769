import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FlexColumnContainer } from "styles/styledComponents"
import { CardPayForm } from "components/CardPayForm"
import axios from "axios"
import { baseURL } from "constants/api"
import { useStateContext } from "contexts/userContext"
import { Link, useNavigate } from "react-router-dom"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { useTranslation } from "react-i18next"

const PAY_IDS = {
  CARD: 1,
  // OTHER: 2,
}

const PAY_ROUTE_PATHNAME = "/payment/execute"

const monthPrice = 10

const periodMonths = {
  30: 1,
  91: 3,
  183: 6,
  365: 12,
}

const periodDiscount = {
  91: 10,
  183: 15,
  365: 20,
}

const options = [
  { id: 30, label: "1 month" },
  { id: 91, label: "3 months" },
  { id: 183, label: "6 months" },
  { id: 365, label: "12 months" },
]

const payOptions = [
  { id: PAY_IDS.CARD, label: "By card" },
  // { id: PAY_IDS.OTHER, label: "By other" },
]

function calculatePeriodPrice(periodId) {
  return periodMonths[periodId] * monthPrice * (1 - (periodDiscount[periodId] || 0) / 100)
}

export function Subscription() {
  const [period, setPeriod] = useState(365)
  const [payMethod, setPayMethod] = useState(null)
  const [errorAlert, setErrorAlert] = useState(false)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
  const [isCardPaymentShow, setIsCardPaymentShow] = useState(false)
  const { restaurant_id } = useStateContext()
  const navigation = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (Object.keys(PAY_IDS).length === 1) {
      setPayMethod(PAY_IDS[Object.keys(PAY_IDS)[0]])
    }
  }, [])

  useEffect(() => {
    if (payMethod === PAY_IDS.CARD) {
      setIsCardPaymentShow(true)
    } else {
      setIsCardPaymentShow(false)
    }
  }, [payMethod])

  const onPeriodChange = useCallback((event, newValue) => {
    setPeriod(newValue.props.value)
  }, [])

  const onPayMenthodChange = useCallback((event, newValue) => {
    setPayMethod(newValue.props.value)
  }, [])

  const servicesList = useMemo(() => [{ type: "subscription", period, sum: calculatePeriodPrice(period) }], [period])

  const onPaymentSuccess = useCallback(() => {
    setIsPaymentSuccess(true)
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      await axios.post(baseURL + PAY_ROUTE_PATHNAME, {
        services: servicesList,
        restaurant_id,
      })
      onPaymentSuccess()
    } catch (error) {
      setErrorAlert(error.message)
    }
  }, [period])

  return (
    <FlexColumnContainer sx={{ m: 3, maxWidth: "360px" }}>
      <FormControl fullWidth>
        <InputLabel id="pay-period-label">{t("Period")}</InputLabel>

        <Select value={period} onChange={onPeriodChange} labelId="pay-period-label" label={t("Period")}>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>
                  <span style={{ marginRight: "10px" }}>{t(option.label)}</span>

                  <span style={{ color: "#888" }}>
                    {periodDiscount[option.id] ? `${periodDiscount[option.id]}% ` + t("discount") : t("No discount")}
                  </span>
                </span>

                <span>${calculatePeriodPrice(option.id)}</span>
              </span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        {/* <FormControl variant="standard" fullWidth> */}

        {/* <InputLabel id="pay-method-label"  >{t('Pay method')}</InputLabel> */}

        <InputLabel id="pay-method-label" shrink>
          {t("Pay method")}
        </InputLabel>

        <Select
          value={payMethod}
          onChange={onPayMenthodChange}
          label={t("Pay method")}
          labelId="pay-method-label"
          required={true}
        >
          {payOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isCardPaymentShow ? (
        <>
          <div style={{ marginBlock: "20px" }}></div>
          <CardPayForm routePathname={PAY_ROUTE_PATHNAME} services={servicesList} onSuccess={onPaymentSuccess} />
        </>
      ) : (
        <>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={!payMethod}>
              {t("To pay")}
            </Button>

            {!payMethod ? (
              <span className="gray" style={{ fontSize: "11px" }}>
                {t("Please, select pay method")}
              </span>
            ) : null}
          </div>{" "}
          {errorAlert && (
            <Box className="notification">
              <Alert severity="error">
                <AlertTitle>{t("Error")}</AlertTitle>
                <strong>{errorAlert}</strong>
              </Alert>
            </Box>
          )}
          {isPaymentSuccess && (
            <Box className="notification">
              <Alert severity="success">
                <AlertTitle>{t("Success")}</AlertTitle>
                <strong>{t("The subscription was successfully paid")}</strong>
              </Alert>
            </Box>
          )}
        </>
      )}
    </FlexColumnContainer>
  )
}
