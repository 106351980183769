import { useState } from "react";
import axios from "axios";

const useImageGeneration = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const generateImage = async (prompt) => {
    if (!prompt?.trim()) {
      setError("Необходимо заполнить название или описание изображения");
      return null;
    }

    const apiKey = process.env.REACT_APP_STABILITY_API_KEY;
    if (!apiKey) {
      setError("API ключ не настроен");
      console.error("Missing REACT_APP_STABILITY_API_KEY in environment variables");
      return null;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST ?? "https://api.stability.ai"}/v1/generation/stable-diffusion-v1-6/text-to-image`,
        {
          text_prompts: [{ text: prompt }],
          cfg_scale: 7,
          width: 512,
          height: 512,
          steps: 30,
          samples: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(`Ошибка ${response.status}: ${response.data.message || response.statusText}`);
      }

      return response.data.artifacts?.[0]?.base64
        ? `data:image/png;base64,${response.data.artifacts[0].base64}`
        : null;
    } catch (err) {
      setError(err.message || "Произошла ошибка при генерации изображения");
      console.error("Image generation error:", err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    generateImage,
  };
};

export default useImageGeneration;
