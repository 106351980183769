import React, { useState, useEffect } from "react"
import { Typography, Box, IconButton, Dialog, DialogContent, Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import theme from "styles/theme"
import { useAuth0 } from "@auth0/auth0-react"
import InfoIcon from "@mui/icons-material/Info"
import { useTranslation } from "react-i18next"
import { useStateContext } from "contexts/userContext"
import { client_url } from "constants/api"

export const Header = () => {
  const { isAuthenticated } = useAuth0()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [imageExists, setImageExists] = useState(false)
  const { t } = useTranslation()
  const { restaurant_id } = useStateContext()
  // Извлекаем имя страницы из пути
  const namePage = location.pathname.slice(1)

  // Проверка доступности изображения
  const checkImageExists = (path) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = path
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
    })
  }

  useEffect(() => {
    if (!namePage) {
      setImageExists(false) // Обнуляем состояние
      return
    }

    const imagePath = `/images/instructions/${namePage}/${namePage}.png`

    // Проверяем существование изображения
    checkImageExists(imagePath).then((exists) => {
      setImageExists(exists)
      console.log(`Image exists for ${namePage}:`, exists)
    })
  }, [namePage]) // Выполняется при изменении маршрута

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Форматирование названия страницы
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const link_to_menu = `${client_url}?restaurant_id=${restaurant_id}`

  return (
    <>
      {isAuthenticated && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.blue2,
            textAlign: "center",
            position: "relative",
            display: "flex", // Используем flexbox
            alignItems: "center", // Выравнивание по вертикали
            justifyContent: "space-between", // Распределяем элементы по краям
            padding: "10px", // Отступы для корректного расположения
          }}
        >
          {/* Кнопка Link */}
          <Button
            variant="outlined"
            sx={{ color: "#4d27d7" }}
            onClick={() => window.open(link_to_menu, "_blank")}
          >
            {t("Link")}
          </Button>

          {/* Заголовок */}
          <Typography variant="h4" sx={{ margin: "0 auto" }}>
            {!namePage ? t("Home Page") : t(capitalizeFirstLetter(namePage))}
          </Typography>

          {/* Кнопка с иконкой */}
          {imageExists && (
            <IconButton
              sx={{ position: "static" }} // Отменяем абсолютное позиционирование
              color="inherit"
              onClick={handleClickOpen}
            >
              <InfoIcon />
            </IconButton>
          )}

          {/* Диалог с изображением */}
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <img
                src={`/images/instructions/${namePage}/${namePage}.png`}
                alt="instruction"
                style={{ width: "100%" }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  )
}
